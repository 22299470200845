import { createGlobalStyle } from 'styled-components';


export const GlobalStyles = createGlobalStyle`
.ql-container .ql-snow {
    height: '10em'
}

.react-datepicker-wrapper {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    opacity: 0;
}

.css-805ymw {
    background-color: rgb(255, 255, 255);
    color: rgb(38, 38, 38);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    position: relative;
    border: 1px solid rgb(230, 235, 241);
    border-radius: 4px;
    box-shadow: inherit;
    min-height: 168px !important;
    max-height: 19em;
}

.css-1ofqig9 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.css-s1vfm9 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-transform: capitalize;
    font-family: "Public Sans", sans-serif;
    font-size: 0.8125rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 3px 9px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgb(140, 140, 140);
    color: rgb(140, 140, 140);
    box-shadow: none;
    font-weight: 400;
    
}

.css-73taos {
    display: inherit;
    margin-right: 8px;
    margin-left: -2px;
}

.css-gcc2o7-MuiButton-startIcon>*:nth-of-type(1) {
    font-size: 1em !important;
}

.css-1h6bc7l{
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0px;
}

.css-1etx6i0-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 3px 0px 3px 10px;
}

.MuiAutocomplete-hasPopupIcon.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 40px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.css-e9ntr5 {
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.57;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(140, 140, 140);
}

.css-9jay18 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px;
}

.css-16ogmd7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.css-1ex1afd-MuiTableCell-root:first-of-type{
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.css-1ex1afd-MuiTableCell-root{
    border-Right: 1px solid rgba(224, 224, 224, 1);
}

.css-15wwp11-MuiTableHead-root{
    background: #e3e3e3;
}

.css-1ygcj2i-MuiTableCell-root:first-of-type{
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.css-1ygcj2i-MuiTableCell-root:last-of-type{
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.css-1ygcj2i-MuiTableCell-root{
    border-Right: 1px solid rgb(255 255 255);
}

.qrcode canvas {
    width: 60px !important;
    height: 60px !important;
    display: flex;
}

.MuiStack-root.css-13qnck4-MuiStack-root {
    margin: 0em 0em 1em 0em;
}

.apexcharts-toolbar {
    margin-top: -4em;
}

span.MuiBadge-badge.MuiBadge-standard.MuiBadge-anchorOriginTopRight.MuiBadge-anchorOriginTopRightRectangular.MuiBadge-overlapRectangular.MuiBadge-colorSecondary.css-wiglhg-MuiBadge-badge {
    position: relative;
    top: 3em;
    right: 3em;
    margin-left: 1em;
    background: #faad142e;
    padding: 8px 10px;
    height: 20px;
    color: #faad14;
    font-weight: bold;
}
`;
