// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import products from './products';
import invoices from './invoices';
import stock from './stock';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, products, invoices, stock, pages, utilities]
};

export default menuItems;
