// assets
import { ChromeOutlined, QuestionOutlined, InboxOutlined, UnorderedListOutlined, AppstoreAddOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  InboxOutlined,
  UnorderedListOutlined,
  AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const products = {
  id: 'products',
  title: 'Products',
  type: 'group',
  children: [
    {
      id: 'add-product',
      title: 'New Product',
      type: 'item',
      url: '/new-product',
      icon: icons.InboxOutlined
    },
    {
      id: 'list-products',
      title: 'List Products',
      type: 'item',
      url: '/list-products',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'category',
      title: 'Category',
      type: 'item',
      url: '/category',
      icon: icons.AppstoreAddOutlined
    }
  ]
};

export default products;
