// assets
import { ChromeOutlined, QuestionOutlined, FileAddOutlined, UnorderedListOutlined, AppstoreAddOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
  AppstoreAddOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const invoices = {
  id: 'invoices',
  title: 'Invoices',
  type: 'group',
  children: [
    {
      id: 'add-invoice',
      title: 'New Invoice',
      type: 'item',
      url: '/new-invoice',
      icon: icons.FileAddOutlined
    },
    {
      id: 'list-invoices',
      title: 'List Invoices',
      type: 'item',
      url: '/list-invoices',
      icon: icons.UnorderedListOutlined
    },
    // {
    //   id: 'category',
    //   title: 'Category',
    //   type: 'item',
    //   url: '/category',
    //   icon: icons.AppstoreAddOutlined
    // }
  ]
};

export default invoices;
