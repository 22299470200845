import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ListProducts from 'pages/components-overview/ListProducts';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
const ListUsers = Loadable(lazy(() => import('pages/components-overview/ListUsers')));
const NewProducts = Loadable(lazy(() => import('pages/components-overview/NewProducts')));
const NewCategory = Loadable(lazy(() => import('pages/components-overview/Category')));
const Invoices = Loadable(lazy(() => import('pages/components-overview/NewInvoice')));
const ListInvoices = Loadable(lazy(() => import('pages/components-overview/ListInvoices')));
const NewStock = Loadable(lazy(() => import('pages/components-overview/NewStock')));
const ListStock = Loadable(lazy(() => import('pages/components-overview/ListStock')));
const InvoiceTemplate = Loadable(lazy(() => import('components/templates/invoice.template')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/new-product',
      element: <NewProducts />
    },
    {
      path: '/category',
      element: <NewCategory />
    },
    {
      path: '/new-invoice',
      element: <Invoices />
    },
    {
      path: '/test-invoice',
      element: <InvoiceTemplate />
    },
    {
      path: '/list-invoices',
      element: <ListInvoices />
    },
    {
      path: '/new-stock',
      element: <NewStock />
    },
    {
      path: '/list-stock',
      element: <ListStock />
    },
    {
      path: '/all-users',
      element: <ListUsers />
    },
    {
      path: '/list-products',
      element: <ListProducts />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
