import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { TextField, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button, InputAdornment } from '@mui/material';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import Switch from '@mui/material/Switch';
import ComponentSkeleton from './ComponentSkeleton';
import MainCard from 'components/MainCard';

const columns = [
    { id: 'sku', label: 'SKU', minWidth: 80 },
    { id: 'image', label: 'Image', minWidth: 80 },
    { id: 'title', label: 'Title', minWidth: 160 },
    { id: 'prices', label: 'P.V', minWidth: 80 },
    { id: 'cost_per_item', label: 'P.U', minWidth: 80 },
    { id: 'margin', label: 'Margin', minWidth: 80 },
    { id: 'tax', label: 'Tax', minWidth: 80 },
    { id: 'height', label: 'Height', minWidth: 80 },
    { id: 'colorsId', label: 'Colors', minWidth: 100 },
    { id: 'available_stk', label: 'T. Stock', minWidth: 80 },
    { id: 'action', label: 'ACTIONS', minWidth: 80 },
    { id: 'show', label: 'SHOW', minWidth: 50 },
];

const ListProducts = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [products, setProducts] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors] = useState([]); // Define colors state
    const [colorRefs, setColorRefs] = useState({});
    const [priceValue, setPriceValue] = useState({});
    const [marginValue, setMarginValue] = useState({});
    const [priceAchatValue, setPriceAchatValue] = useState({});
    const [priceCurrency, setPriceCurrency] = useState({});
    const [priceTax, setPriceTax] = useState({});
    const [loading, setLoading] = useState(false); // State for loading indicator
    const [totalProducts, setTotalProducts] = useState(0); // State for total products
    const [totalStock, setTotalStock] = useState(0); // State for total stock
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsResponse = await axios.get('http://available-stock.com/server/product/getAll');
                setProducts(productsResponse.data);
                setTotalProducts(productsResponse.data.length);

                const colorsResponse = await axios.get('http://available-stock.com/server/color/getAll');
                setColors(colorsResponse.data);

                const pricesResponse = await axios.get('http://available-stock.com/server/price/getAll');
                fetchColor(productsResponse.data, colorsResponse.data);
                fetchPrice(productsResponse.data, pricesResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchColor = async (products, colors) => {
        const colorRefs = {};
        let total = 0;

        for (const product of products) {
            let productTotalStock = 0; // Initialize total stock for the current product

            // Iterate through each colorId in the product
            for (const colorId of product.colorsId) {
                const color = colors.find(color => color._id === colorId); // Find the color data for the product
                if (color && color.stock_color) { // Check if color and stock_color are defined
                    if (!colorRefs[color._id]) {
                        // Fetch the color reference data if not already fetched
                        try {
                            const response = await axios.get(`http://available-stock.com/server/color/${colorId}`);
                            colorRefs[color._id] = { ...color, refColor: response.data.refColor };
                        } catch (error) {
                            console.error('Error fetching color reference:', error);
                            colorRefs[color._id] = { ...color, refColor: 'N/A' }; // Use default value if fetching fails
                        }
                    }
                    productTotalStock += parseInt(color.stock_color); // Sum up the stock for each color
                }
            }

            // Update the product's total stock
            product.available_stk = productTotalStock;

            // Accumulate total stock
            total += productTotalStock;
        }
        // Update the color references state
        setColorRefs(colorRefs);
        // Update the total stock state
        setTotalStock(total);
        // Update the products state with calculated total stock
        setProducts([...products]);
    };

    const fetchPrice = async (products, prices) => {
        const priceValue = {};
        const priceAchat = {};
        const priceCurrency = {};
        const priceTax = {};
        const Margin = {};

        for (const product of products) {
            const priceId = product.priceId; // Get the priceId directly from the product
            const price = prices.find(price => price._id === priceId); // Find the price data

            if (price && price.currency) {
                try {
                    const response = await axios.get(`http://available-stock.com/server/price/${priceId}`);
                    // Store the price data
                    priceValue[priceId] = { ...price, price: response.data.price };
                    Margin[priceId] = { ...price, margin: parseFloat(response.data.margin).toFixed(1) };
                    priceAchat[priceId] = { ...price, cost_per_item: response.data.cost_per_item };
                    priceCurrency[priceId] = { ...price, currency: response.data.currency }; // Ensure this line is correct
                    priceTax[priceId] = { ...price, tax: response.data.tax }; // Ensure this line is correct
                } catch (error) {
                    console.error('Error fetching price data:', error);
                    // If fetching fails, store 'N/A'
                    priceValue[priceId] = { ...price, price: 'N/A' };
                    Margin[priceId] = { ...price, margin: 'N/A' };
                    priceAchat[priceId] = { ...price, cost_per_item: 'N/A' };
                    priceCurrency[priceId] = { ...price, currency: 'N/A' };
                    priceTax[priceId] = { ...price, tax: 'N/A' };
                }
            }
        }

        setPriceValue(priceValue);
        setMarginValue(Margin);
        setPriceAchatValue(priceAchat);
        setPriceCurrency(priceCurrency);
        setPriceTax(priceTax);
    };


    const renderColors = (colorIds) => {
        if (!colorIds || colorIds.length === 0) return null;
        const visibleColors = colorIds.slice(0, 3).map(colorId => (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                    alignItems: 'center'
                }}

            >
                <div
                    key={colorId}
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: colorRefs[colorId] ? colorRefs[colorId].refColor : '', // Use fetched color ref
                        display: 'inline-block',
                        marginRight: '5px',
                        border: '1px solid rgb(185 185 185)'
                    }}
                >
                </div>
                <p>{colorRefs[colorId] ? colorRefs[colorId].name : ''}</p>

            </div>
        ));
        const otherColorsCount = colorIds.length - visibleColors.length;
        if (otherColorsCount > 0) {
            visibleColors.push(
                <span key="other" style={{ marginRight: '5px', opacity: '.8' }}>+{otherColorsCount} color</span>
            );
        }
        return visibleColors;
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter products based on search query
    const filteredProducts = products.filter((product) => {
        const title = product.title || ''; // Ensure title is not undefined
        const sku = product.sku || ''; // Ensure sku is not undefined
        return (
            title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            sku.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <ComponentSkeleton>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <AnalyticEcommerce title="Total Products" loading={loading} count={totalProducts} percentage={59.3} extra="35,000" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <AnalyticEcommerce title="Total Stock" loading={loading} count={totalStock} percentage={70.5} extra="8,900" />
                        </Grid>
                    </Grid>
                    <Stack spacing={3} marginTop={2}>
                        <MainCard title="Added new Product" codeHighlight>

                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '100%' }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TextField
                                                    label="Search by name or SKU"
                                                    value={searchQuery}
                                                    onChange={handleSearchChange}
                                                    variant="outlined"
                                                    margin="normal"
                                                    sx={{ marginLeft: '1em', width: '14em' }}
                                                    fullWidth
                                                    startAdornment={
                                                        <InputAdornment position="start" sx={{ mr: -0.5 }}>
                                                            <SearchOutlined />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </TableRow>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align="left"
                                                        style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loading ? ( // Check loading state before rendering table rows
                                                <TableRow>
                                                    <TableCell colSpan={columns.length} align="center">
                                                        <LoadingOutlined />
                                                        Loading...
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                filteredProducts // Use filtered products for rendering
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((product) => (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={product._id}>
                                                            {columns.map((column) => {
                                                                const value = product[column.id];
                                                                if (column.id === 'show') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            <Switch defaultChecked />
                                                                        </TableCell>
                                                                    )
                                                                }
                                                                if (column.id === 'image') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {product.images.length > 0 && (
                                                                                <div
                                                                                    key={product.images}
                                                                                    style={{
                                                                                        width: '60px',
                                                                                        height: '60px',
                                                                                        backgroundImage: `url(${product.images}?w=164&h=164&fit=crop&auto=format)`,
                                                                                        backgroundSize: 'cover',
                                                                                        backgroundPosition: 'center',
                                                                                    }}
                                                                                ></div>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id === 'colorsId') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {renderColors(product.colorsId)}
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id === 'cost_per_item') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {priceAchatValue[product.priceId] ? `${priceAchatValue[product.priceId].cost_per_item} ${priceCurrency[product.priceId].currency}` : 'N/A'}
                                                                        </TableCell>

                                                                    );
                                                                }

                                                                if (column.id === 'prices') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {priceValue[product.priceId] ? `${priceValue[product.priceId].price} ${priceCurrency[product.priceId].currency}` : 'N/A'}
                                                                        </TableCell>

                                                                    );
                                                                }

                                                                // if (column.id === 'margin') {
                                                                //     const marginPriceValue = marginValue[product.priceId]?.margin;
                                                                //     const displayMargin = typeof marginPriceValue === 'number' ? `${marginPriceValue.toFixed(1)} %` : '0 %';
                                                                //     return (
                                                                //         <TableCell key={column.id} align="left">
                                                                //             {displayMargin}
                                                                //         </TableCell>
                                                                //     );
                                                                // }

                                                                if (column.id === 'margin') {
                                                                    const marginPriceValue = marginValue[product.priceId]?.margin;
                                                                    const displayMargin = marginPriceValue != null ? `${marginPriceValue} %` : '0 %';
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {displayMargin}
                                                                        </TableCell>
                                                                    );
                                                                }



                                                                if (column.id === 'tax') {
                                                                    const taxValue = priceTax[product.priceId]?.tax;
                                                                    const displayTax = taxValue != null ? `${taxValue} %` : '0 %';
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {displayTax}
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id === 'height') {
                                                                    // const productsValue = products[product.priceId]?.height;
                                                                    const displayHeight = `6.00 m`;
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            {displayHeight}
                                                                        </TableCell>
                                                                    );
                                                                }


                                                                if (column.id === 'action') {
                                                                    return (
                                                                        <TableCell key={column.id} align="left">
                                                                            <Button style={{ border: "none", padding: "0" }} variant="outlined" color="primary">
                                                                                <EyeOutlined />
                                                                            </Button>
                                                                            <Button style={{ border: "none", padding: "0" }} variant="outlined" color="primary">
                                                                                <EditOutlined />
                                                                            </Button>
                                                                            <Button style={{ border: "none", padding: "0" }} variant="outlined" color="error">
                                                                                <DeleteOutlined />
                                                                            </Button>
                                                                        </TableCell>
                                                                    );
                                                                }
                                                                return (
                                                                    <TableCell key={column.id} align="left">
                                                                        {column.id === 'sku' ? (
                                                                            // Fetch SKU for this product
                                                                            <FetchSkuForProduct skuId={product.skuId} />
                                                                        ) : (
                                                                            value
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={filteredProducts.length} // Use filtered products count for pagination
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </MainCard>
                    </Stack>
                </Grid>
            </Grid>
        </ComponentSkeleton>
    )
};

const FetchSkuForProduct = ({ skuId }) => {
    const [sku, setSku] = useState(null);

    const fetchSkuForProduct = async (skuId) => {
        try {
            const response = await axios.get(`http://available-stock.com/server/Sku/${skuId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching SKU data:', error);
            return [];
        }
    };

    useEffect(() => {
        const fetchSku = async () => {
            const skuData = await fetchSkuForProduct(skuId);
            setSku(skuData);
        };
        fetchSku();
    }, [skuId]);

    if (!sku) {
        return <span>Loading...</span>;
    }

    // Assuming SKU data has a unique identifier named 'skuId'
    return <span>#{sku.sku}</span>;
};

export default ListProducts;
