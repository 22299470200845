export const LogoSVG = () => {
    return (
        <svg width="190" height="70" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1087.18 285.59">
            <defs>
                <style>
                    {`
            .cls-1 {
              letter-spacing: .8em;
            }
      
            .cls-2, .cls-3 {
              fill: #ffac33;
            }
      
            .cls-4, .cls-5 {
              fill: #1c1c1c;
            }
      
            .cls-6 {
              letter-spacing: .78em;
            }
      
            .cls-5 {
              font-family: DINAlternate-Bold, 'DIN Alternate';
              font-size: 66.06px;
            }
      
            .cls-5, .cls-3 {
              font-weight: 700;
            }
      
            .cls-3 {
              font-family: AvenirNextCondensed-Bold, 'Avenir Next Condensed';
              font-size: 45px;
            }
            `}
                </style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <path class="cls-2" d="M119.03,285.59s-25.14-4.64-32.09-25.5v-113.6L27.82,109.38v125.2s-18.55-2.32-27.82-16.23V92s0-22.27,39.41-41.73L119.03,3.89s21.88-12.36,47.9,6.96l82.31,48.69-105.08,59.51c-14.9,8.44-24.62,23.95-25.1,41.06-.01,.47-.02,.95-.02,1.44v124.04ZM85.73,104.95c13.48,8.32,25.77,0,25.77,0l80.47-48.36-40.83-24.18c-10.09-9.18-17.68-4.36-17.68-4.36L46.09,77.2s23.91,18.03,39.64,27.75Z" />
                <path class="cls-4" d="M252.33,85.05l1.35,114.41-22.21,16.15c-2.02,3.36-8.08,3.21-8.08,3.21l.13-27-58.59,42.17,.03,30.09-28.8,20.01v-112c-.35-26.33,20.37-36.16,20.37-36.16l92.69-54.42c3.64-.53,3.1,3.55,3.1,3.55Zm-29.27,45.21l-60.81,36.11v26.56s1.4,4.04,5.82,2.25,54.98-39.3,54.98-39.3v-25.63Z" />
                <text class="cls-3" transform="translate(302.73 89.79)"><tspan class="cls-1" x="0" y="0">A</tspan><tspan class="cls-6" x="60.44" y="0">L</tspan><tspan class="cls-1" x="114.39" y="0">UMINIUM</tspan></text>
                <text class="cls-5" transform="translate(302.65 170.74) scale(1.5 1)"><tspan x="0" y="0">ALAAHD AL JADID</tspan></text>
            </g>
        </svg>
    )
}