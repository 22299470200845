// assets
import { ChromeOutlined, QuestionOutlined, FileAddOutlined, UnorderedListOutlined, AppstoreAddOutlined, GroupOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
  AppstoreAddOutlined,
  GroupOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const stock = {
  id: 'stock',
  title: 'Stock',
  type: 'group',
  children: [
    {
      id: 'add-stock',
      title: 'New stock',
      type: 'item',
      url: '/new-stock',
      icon: icons.GroupOutlined
    },
    {
      id: 'list-stock',
      title: 'List Stock',
      type: 'item',
      url: '/list-stock',
      icon: icons.UnorderedListOutlined
    },
    // {
    //   id: 'category',
    //   title: 'Category',
    //   type: 'item',
    //   url: '/category',
    //   icon: icons.AppstoreAddOutlined
    // }
  ]
};

export default stock;
