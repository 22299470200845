// assets
import { LoginOutlined, ProfileOutlined, UnorderedListOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  UnorderedListOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'users&role',
  title: 'Users and Roles',
  type: 'group',
  children: [
    {
      id: 'register1',
      title: 'Add User',
      type: 'item',
      url: '/register',
      icon: icons.ProfileOutlined,
      target: true
    },
    {
      id: 'register2',
      title: 'Role User',
      type: 'item',
      url: '/update-user',
      icon: icons.LoginOutlined,
    },
    {
      id: 'allUsers',
      title: 'List Users',
      type: 'item',
      url: '/all-users',
      icon: icons.UnorderedListOutlined
    }
  ]
};

export default pages;
